import { createActionGroup, props } from '@ngrx/store'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'
import { FundsTransferType } from '../../../../../../common/models/funds-transfer-type'

export const getTransferHistoryActions = createActionGroup({
	source: 'Transfer History page get transfers',
	events: {
		start: props<{ transfer_type: FundsTransferType[]; page: number }>(),
		success: props<FundsTransferListResponse>(),
		error: props<{ error: string }>()
	}
})

export const allErrors = [getTransferHistoryActions.error]
