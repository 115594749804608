import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { ErrorResponse } from 'common/models/error-response';
import { ProductWithItems } from 'common/models/product-with-items'
import { Category } from "common/models/category";

export const getProductsAndCategories = createActionGroup({
	source: 'Products and Categories load',
	events: {
		start: emptyProps(),
		success: props<{ products: ProductWithItems[]; categories: Category[] }>(),
		error: props<{ error: string }>(),
	},
})

export const setCategory = createAction('[Products] set category', props<{ category: string | null }>())

export const getProduct = createActionGroup({
	source: 'Product load',
	events: {
		start: props<{ slug: string, stock_id: number }>(),
		success: props<{ product: ProductWithItems | null }>(),
		error: props<{ error: string }>(),
	},
})

export const addFavorite = createActionGroup({
	source: 'Add Favorite',
	events: {
		'start': props<{ productId: number }>(),
		'success': props<{ productId: number, success: boolean }>(),
		'error': props<{ error: ErrorResponse }>()
	}
});

export const deleteFavorite = createActionGroup({
	source: 'Delete Favorite',
	events: {
		'start': props<{ productId: number }>(),
		'success': props<{ productId: number, success: boolean }>(),
		'error': props<{ error: ErrorResponse }>()
	}
});

export const clearFavorites = createAction('[Favorite] Clear favorites');

export const setSearchText = createAction('[Search Component] Set Search Text', props<{ searchText: string }>());
