import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'

/**@deprecated use css */
@Component({
  selector: 'app-flex',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flex.component.html',
  styleUrls: ['./flex.component.scss']
})
export class FlexComponent implements OnChanges, OnInit {
  @Input() jc: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' = 'flex-start'
  @Input() ai: 'flex-start' | 'flex-end' | 'center' | 'baseline' = 'center'
  @Input() gap = ''
  @Input() w100 = false
  @Input() h100 = false
  @Input() fd: 'row' | 'column' | 'column-reverse' = 'row'
  @Input() fw: 'wrap' | 'nowrap' = 'nowrap'

  constructor(public ref: ElementRef<HTMLDivElement>) {}

  ngOnChanges(): void {
    this.updateStyles()
  }

  ngOnInit(): void {
    this.updateStyles()
  }

  updateStyles() {
    const host = this.ref.nativeElement

    host.style.alignItems = this.ai
    host.style.justifyContent = this.jc
    host.style.gap = this.gap
    host.style.width = this.w100 ? '100%' : ''
    host.style.height = this.h100 ? '100%' : ''
    host.style.flexDirection = this.fd
    host.style.flexWrap = this.fw
  }
}
