import { Injectable } from '@angular/core'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'
import { RequestService } from 'common/services/request.service'
import { FundsTransferType } from '../../../../../../common/models/funds-transfer-type'

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class TransferHistoryService {
	constructor(private requestService: RequestService) {}

	getTransfers(data: { transfer_type: FundsTransferType[]; page: number }) {
		return this.requestService.post<unknown, FundsTransferListResponse>('api/account/funds_transfer/list_v2', {
			...data,
			page_size: 100
		})
	}
}
