import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Metrika } from 'ng-yandex-metrika'
import { environment } from '../environments/environment'
import { filter, map, mergeMap, Subject, takeUntil, timer } from 'rxjs';
import { SeoService } from './services/seo.service'
import { AppState } from '../store/state'
import { Store } from '@ngrx/store'
import { MatDialog } from '@angular/material/dialog'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../common/services/ls.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Language } from '../../../common/utils/constants/language';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit, OnDestroy {
	constructor(
		private metrika: Metrika,
		private router: Router,
		private route: ActivatedRoute,
		private seoService: SeoService,
		private store: Store<AppState>,
		private _dialog: MatDialog,
		private lang: TranslateService,
		private ls: LocalStorageService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	language: Language | null = null
	destroy$ = new Subject<void>()
	isMobile?: boolean

	ngOnInit() {
		this.isMobile = this.detectMobile()

		let prevPath = this.router.url

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.router.routerState.root),
				map(route => {
					while (route.firstChild) route = route.firstChild
					return route
				}),
				filter(route => route.outlet === 'primary'),
				mergeMap(route => route.data),
				takeUntil(this.destroy$)
			)
			.subscribe(data => {
				this.seoService.updateSeo({
					titleTranslationKey: data['titleTranslationKey'],
					metaTags: data['metaTags'],
					metaProps: data['metaProps']
				})

				if (environment.production) {
					const newPath = this.router.url
					if (newPath !== prevPath) {
						this.metrika.hit(newPath, { referer: prevPath })
						prevPath = newPath
					}
				}
			})

		this.lang.use(
			this.ls.hasItem(this.ls.keys.lang)
				? (this.ls.getItem(this.ls.keys.lang) as string)
				: this.lang.currentLang || this.lang.defaultLang
		)

		this.lang.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((change: LangChangeEvent) => {
			this.ls.setItem(this.ls.keys.lang, change.lang)

			if (this.language) {
				this.document.body.classList.toggle(this.language as unknown as string)
			}

			this.language = change.lang as Language

			this.document.body.classList.toggle(this.language as unknown as string)

			this.document.documentElement.lang = change.lang
		})
	}

	ngOnDestroy() {
		this.destroy$.next()
		this.destroy$.complete()
	}

	private detectMobile(): boolean {
		if (isPlatformBrowser(this.platformId)) {
			return /Android|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
		return false
	}

}
