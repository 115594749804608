import { BonusSystemClientData } from '../../../../common/models/bonus-system-client-data'
import { createReducer } from '@ngrx/store'

export const featureKey = 'accounts'

export interface State {
	isLoading: boolean
	accounts: BonusSystemClientData[]
}

export const initialState: State = {
	isLoading: false,
	accounts: []
}

export const reducer = createReducer(initialState)
