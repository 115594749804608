import { Injectable } from '@angular/core'
import { Item } from 'common/models/item'
import { ProductWithItems } from 'common/models/product-with-items'
import { RequestService } from 'common/services/request.service'
import { Observable, forkJoin } from 'rxjs'
import { defaultCountryCode } from 'common/utils/currency/default-currency'
import { SuccessResponse } from 'common/models/success-response'
import { Category } from "common/models/category";

@Injectable({
	providedIn: 'root'
})
export class ShopService {
	constructor(private readonly requestService: RequestService) {}

	public getProducts() {
		let countryId = defaultCountryCode()
		return this.requestService.post<Record<string, never>, ProductWithItems[]>(
			'api/products/list?country_id=' + countryId,
			{}
		)
	}

	public getCategories() {
		return this.requestService.get<Category[]>('api//products/v2/categories');
	}

	public getProductsAndCategories() {
		return forkJoin([this.getProducts(), this.getCategories()])
	}

	public getSingleProduct(slug: string, stock_id: number): Observable<ProductWithItems> {
		return this.requestService.get<ProductWithItems>(`api/products/${slug}?stock_id=` + stock_id, {})
	}

	public addFavoriteProduct(productId: number): Observable<SuccessResponse> {
		return this.requestService.post<unknown, SuccessResponse>('api/products/favourites', { productId: productId })
	}

	public deleteFavoriteProduct(productId: number): Observable<SuccessResponse> {
		return this.requestService.delete<unknown, SuccessResponse>(`api/products/favourites/${productId}`, {})
	}
}
