import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TransferHistoryService } from '../api/transfer-history.service'
import { getTransferHistoryActions } from './transfer-history.actions'

@Injectable()
export class TransferHistoryEffects {
  constructor(private actions$: Actions, private transferHistoryService: TransferHistoryService) {}

  loadTransferHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTransferHistoryActions.start),
      mergeMap(({ page, transfer_type }) =>
        this.transferHistoryService.getTransfers({ page, transfer_type }).pipe(
          map(response => getTransferHistoryActions.success(response)),
          catchError(error => of(getTransferHistoryActions.error({ error: String(error) })))
        )
      )
    )
  )
}
